@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@import 'antd/dist/reset.css';

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

body[data-theme='dark'] .ant-menu-root {
  background-color: unset !important;
}

body[data-theme='dark'] .iconic-panel svg {
  stroke: #fff;
}

[ant-click-animating-without-extra-node='true']::after {
  display: none !important;
}

.ant-breadcrumb ol {
  list-style: none;
  padding: 0;
  display: inline-flex;
}

.ant-form-item-margin-offset {
  margin-bottom: 0 !important;
}

.ml-auto {
  margin-left: auto;
}

.ant-card-body {
  overflow: auto;
}

.ant-avatar > img {
  object-fit: contain !important;
}

.ant-modal-content {
  border-radius: 10px !important;
}

.ant-btn:not(.ant-input-group-addon button, .ant-btn-circle) {
  border-radius: 5px !important;
}

.ant-btn.ant-btn-default:focus {
  color: unset !important;
  border-color: rgb(217, 217, 217) !important;
}

.ant-modal-header {
  border-radius: 10px 10px 0 0 !important;
}

.ant-layout-sider-children {
  margin-bottom: 50px;
}

body[data-theme='dark'] .ant-tag-orange {
  background-color: #222 !important;
}

.ant-layout-sider-collapsed .ant-layout-sider-children {
  margin-bottom: 0;
}

body[data-theme='dark'] .ant-tag .anticon {
  color: #f2f2f2 !important;
}

.ant-warning {
  border-color: orange !important;
  color: orange !important;
}

.ant-warning:disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  border-color: rgb(217, 217, 217) !important;
  background: rgb(245, 245, 245) !important;
  text-shadow: none !important;
  box-shadow: none !important;
}

body[data-theme='dark'] .ant-warning:disabled {
  color: rgba(255, 255, 255, 0.3) !important;
  border-color: #434343 !important;
  background: rgba(255, 255, 255, 0.08) !important;
  text-shadow: none !important;
  box-shadow: none !important;
}

.ant-secondary {
  border-color: rgb(187, 187, 187) !important;
  color: rgb(110, 110, 110) !important;
}

.ant-primary {
  border-color: rgb(24, 144, 255) !important;
  color: rgb(24, 144, 255) !important;
}

.ant-success {
  border-color: rgb(7, 196, 85) !important;
  color: rgb(7, 196, 85) !important;
}

.ant-form-item-label {
  text-align: left !important;
}

.ant-form-rtl .ant-form-item-label {
  text-align: right !important;
}

.form-container {
  min-height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  background-image: url(https://cdn.1cdn.app/application/LUMBERJACK/22101008432098_back_greand_2_1.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.form-container .language-dropdown {
  position: absolute;
  right: 5%;
  background-color: #ee5253;
  color: #fff;
  padding: 9px;
  border-radius: 10px;
  height: 40px;
  cursor: pointer;
}

.form-container .logout-btn {
  position: absolute;
  left: 5%;
}

.form-container .language-dropdown .ant-space-item {
  line-height: 0;
}

.form-container .language-dropdown svg {
  line-height: 0;
  font-size: 22px;
}

.form-container .language-dropdown .ant-dropdown-link {
  color: #333;
  font-size: 22px;
}

.form-container.register-page .ant-card {
  margin: 25px;
}

.form-container .logo {
  text-align: center;
  margin-bottom: 10px;
}

.form-container .logo a h4 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 52px;
}

.form-container .logo a h4 span {
  color: #ee5253;
}

.loginRegisterTab {
  margin-bottom: 30px;
}

.loginRegisterTab ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.loginRegisterTab ul li {
  width: calc(100% / 2);
  background-color: #c3c3c3;
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.loginRegisterTab ul li:first-child {
  border-radius: 15px 0 0 15px;
}

.loginRegisterTab ul li:last-child {
  border-radius: 0 15px 15px 0;
}

.ant-card-rtl .loginRegisterTab ul li:first-child {
  border-radius: 0 15px 15px 0;
}

.ant-card-rtl .loginRegisterTab ul li:last-child {
  border-radius: 15px 0 0 15px;
}

.loginRegisterTab ul li a {
  padding: 15px;
  display: block;
  color: #fff;
  font-weight: 400;
}

.loginRegisterTab ul li.active {
  background-color: #ee5253;
}

.content-wrapper .ant-form-item-label {
  padding-bottom: 0 !important;
}

.content-wrapper .ant-form-item-label label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 38px;
  color: #666666;
}

.content-wrapper .ant-form-item-control-input-content input,
.content-wrapper .ant-input-password {
  border: 2px solid #c9c9c9;
  border-radius: 10px;
}

.content-wrapper .ant-checkbox-inner {
  border: 2px solid #c9c9c9;
}

.content-wrapper .ant-form-item-control-input-content input::placeholder {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #c9c9c9;
}

.login-form-passless {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ee5253;
}

.login-form-passless:hover {
  color: #eb4040;
}

.ant-layout-sider-collapsed .logo .ant-space-item {
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
}

.ant-layout-sider-collapsed .logo .ant-space-item:first-child {
  gap: 0;
  margin-left: 8px;
}

.ant-layout-sider-collapsed .logo .ant-space-item:last-child {
  display: none;
}

.site-sidebar .logo {
  height: 32px;
  margin: 16px;
}

.site-sidebar ul li:first-child {
  margin-top: 0;
}

.site-layout .site-layout-header {
  padding: 0;
  margin: 0;
  border-bottom: none;
}

.site-layout .site-layout-header .site-layout .site-layout-header .ant-menu-item-icon,
.site-layout .site-layout-header .anticon {
  font-size: 18px;
}

.site-layout .site-layout-header ul .ant-menu-title-content {
  margin: 0;
}

/* Ui error page */
.ui-error {
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: #222;
  color: #fff;
}

.ui-error #error {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.075em;
  color: #c94d4d;
  -webkit-animation: pulse 4s infinite alternate;
  animation: pulse 4s infinite alternate;
  position: relative;
}

@-webkit-keyframes pulse {
  from {
    opacity: 0.5;
  }

  50% {
    opacity: 0.5;
  }
}

@keyframes pulse {
  from {
    opacity: 0.5;
  }

  50% {
    opacity: 0.5;
  }
}

#error::before {
  content: '';
  width: 0.75rem;
  height: 50vh;
  margin-bottom: 0.75em;
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 60%, transparent 100%);
}

#desc {
  margin: 2em 0 1em;
}

.error-num,
.error-num__clip,
.error-num__clip-left,
.error-num__clip-left::before,
.error-num__clip::before {
  position: relative;
  font-size: 10rem;
  font-family: 'Oswald';
  letter-spacing: -0.01em;
  color: white;
  -webkit-animation: colorSplit 1.25s steps(2, end) infinite;
  animation: colorSplit 1.25s steps(2, end) infinite;
}

@-webkit-keyframes colorSplit {
  25% {
    text-shadow: -0.02em 0 0 #ed008c, 0.025em 0 0 #0087ef;
  }

  75% {
    text-shadow: -0.035em 0 0 #ed008c, 0.04em 0 0 #0087ef;
  }
}

@keyframes colorSplit {
  25% {
    text-shadow: -0.02em 0 0 #ed008c, 0.025em 0 0 #0087ef;
  }

  75% {
    text-shadow: -0.035em 0 0 #ed008c, 0.04em 0 0 #0087ef;
  }
}

.error-num__clip,
.error-num__clip-left,
.error-num__clip-left::before,
.error-num__clip::before {
  position: absolute;
  top: 0;
  left: -2px;
  z-index: 10;
  color: #333;
  overflow: visible;
  -webkit-clip-path: polygon(
    0% 0%,
    100% 0,
    100% 25%,
    0 25%,
    0 30%,
    100% 30%,
    100% 50%,
    0 50%,
    0 60%,
    100% 60%,
    100% 65%,
    0 65%,
    0 80%,
    100% 80%,
    100% 85%,
    0 85%,
    0% 0%
  );
  clip-path: polygon(
    0% 0%,
    100% 0,
    100% 25%,
    0 25%,
    0 30%,
    100% 30%,
    100% 50%,
    0 50%,
    0 60%,
    100% 60%,
    100% 65%,
    0 65%,
    0 80%,
    100% 80%,
    100% 85%,
    0 85%,
    0% 0%
  );
  -webkit-animation: glitch 1s steps(2, start) infinite;
  animation: glitch 1s steps(2, start) infinite;
}

@-webkit-keyframes glitch {
  30% {
    left: 0;
  }

  to {
    left: 0;
  }
}

@keyframes glitch {
  30% {
    left: 0;
  }

  to {
    left: 0;
  }
}

.error-num__clip::before,
.error-num__clip-left::before {
  content: '500';
  left: 0.05em;
  color: white;
  z-index: 9;
  -webkit-clip-path: polygon(
    0% 0%,
    100% 0,
    100% 26%,
    0 26%,
    0 29%,
    100% 29%,
    100% 51%,
    0 51%,
    0 59%,
    100% 59%,
    100% 66%,
    0 66%,
    0 79%,
    100% 79%,
    100% 86%,
    0 86%,
    0% 0%
  );
  clip-path: polygon(
    0% 0%,
    100% 0,
    100% 26%,
    0 26%,
    0 29%,
    100% 29%,
    100% 51%,
    0 51%,
    0 59%,
    100% 59%,
    100% 66%,
    0 66%,
    0 79%,
    100% 79%,
    100% 86%,
    0 86%,
    0% 0%
  );
}

.usage-counter {
  padding: 25px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin: auto;
  text-align: center;
}

.usage-counter img {
  width: 50%;
}

.ant-layout-sider-collapsed .usage-counter {
  display: none;
}

.custom-collapse {
  margin-bottom: 24px;
  overflow: hidden;
  background: transparent !important;
  border: 0px;
  border-radius: 5px !important;
}

.custom-collapse .ant-collapse-item {
  margin-bottom: 20px;
  border: none !important;
  border-radius: 15px !important;
}

.custom-collapse .ant-collapse-header {
  font-size: 14px;
  font-weight: 600;
}

.ant-card {
  border-radius: 15px !important;
}

/* Offers */
.lk-red-button {
  background-color: #ee5253 !important;
  border-radius: 10px !important;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.lk-red-button:hover {
  -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  color: #fff;
}

.list-unstyled {
  list-style: none;
  padding: 0;
}

.section-6 .lk-subs-type {
  width: 250px;
  background-color: #fff;
  margin: auto;
  padding: 20px;
  -webkit-box-shadow: 0px 12px 20px -16px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 12px 20px -16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}

.section-6 .lk-subs-type ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 0;
}

.section-6 .lk-subs-type ul li:not(:nth-child(2)) {
  cursor: pointer;
  border-bottom: 2px solid transparent;
  font-weight: 400;
}

.section-6 .lk-subs-type ul li:nth-child(2) {
  color: #e3e3e3;
}

.section-6 .lk-subs-type ul li.active {
  border-bottom: 2px solid #ee5253;
  color: #ee5253;
}

.section-6 .animate {
  top: -150px;
  left: 70px;
}

.section-6 .lk-offers {
  margin-top: 1rem;
}

.section-6 .lk-offers .lk-offers-card {
  background-color: #f8f8f8;
  border-radius: 20px;
  -webkit-box-shadow: 10px 3px 6px 0px rgba(26, 26, 26, 0.02);
  box-shadow: 10px 3px 6px 0px rgba(26, 26, 26, 0.02);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.section-6 .lk-offers .lk-offers-card .current-active-plan {
  background-color: #ea5153;
  text-align: center;
  color: #fff;
  padding: 15px 10px 16px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  border-radius: 20px 20px 0 0;
}

.section-6 .lk-offers .lk-offers-card:hover,
.section-6 .lk-offers .lk-offers-card.active {
  background-color: #ea5153;
}

.section-6 .lk-offers .lk-offers-card:hover .current-active-plan {
  background-color: #f8f8f8;
  color: #ea5153;
}

.section-6 .lk-offers .lk-offers-card .title {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 20px 0 20px;
  gap: 10px;
}

.section-6 .lk-offers .lk-offers-card .title .badge {
  background-color: #ea5153;
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding: 4px;
}

.section-6 .lk-offers .lk-offers-card .title .badge p {
  margin-bottom: 0;
  margin-left: 5px;
  color: #fff;
}

.section-6 .lk-offers .lk-offers-card:hover .title .badge p,
.section-6 .lk-offers .lk-offers-card.active .title .badge p {
  color: #ea5153;
}

.section-6 .lk-offers .lk-offers-card .title h6 {
  font-size: 22px;
  color: #470000;
  font-weight: 200;
  margin-bottom: 0;
}

.section-6 .lk-offers .lk-offers-card:hover .title h6,
.section-6 .lk-offers .lk-offers-card.active .title h6 {
  color: #fff;
}

.section-6 .lk-offers .lk-offers-card:hover .title .badge,
.section-6 .lk-offers .lk-offers-card.active .title .badge {
  background-color: #ffffff;
  color: #ea5153;
}

.section-6 .lk-offers .lk-offers-card .title .badge svg {
  fill: #ffffff;
}

.section-6 .lk-offers .lk-offers-card:hover .title .badge svg,
.section-6 .lk-offers .lk-offers-card.active .title .badge svg {
  fill: #ea5153;
}

.section-6 .lk-offers .lk-offers-card .price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 20px;
}

.section-6 .lk-offers .lk-offers-card .price h3 {
  color: #470000;
  font-size: 32px;
}

.section-6 .lk-offers .lk-offers-card:hover .price h3,
.section-6 .lk-offers .lk-offers-card.active .price h3 {
  color: #fff;
}

.section-6 .lk-offers .lk-offers-card .price .offer {
  margin-left: 10px;
  font-size: 18px;
  color: #000;
  -webkit-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}

.section-6 .lk-offers .lk-offers-card:hover .price .offer,
.section-6 .lk-offers .lk-offers-card.active .price .offer {
  color: #fff;
  font-size: 28px;
}

.section-6 .lk-offers .lk-offers-card .price .discount {
  font-size: 28px;
  margin-left: 10px;
}

.section-6 .lk-offers .lk-offers-card .price .discount del {
  color: rgba(71, 0, 0, 0.25);
}

.section-6 .lk-offers .lk-offers-card:hover .price .discount del,
.section-6 .lk-offers .lk-offers-card.active .price .discount del {
  color: #fff;
}

.section-6 .lk-offers .lk-offers-card .desc p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}

.section-6 .lk-offers #free .lk-offers-card .desc p,
.section-6 .lk-offers .lk-offers-card .desc p.is-monthly {
  color: #470000;
  font-size: 16px;
  padding: 0 20px;
}

.section-6 .lk-offers #free .lk-offers-card:hover .desc p,
.section-6 .lk-offers #free .lk-offers-card.active .desc p {
  color: #ffffff;
}

.section-6 .lk-offers .lk-offers-card .desc p svg,
.section-6 .lk-offers .lk-offers-card .desc p svg {
  fill: #ee5253;
  margin-right: 8px;
}

.section-6 .lk-offers .lk-offers-card:hover .desc p svg,
.section-6 .lk-offers .lk-offers-card.active .desc p svg {
  fill: #ffffff;
}

.section-6 .lk-offers .lk-offers-card .desc p {
  color: #c7b2b2;
  font-size: 14px;
  padding: 0 20px;
}

.section-6 .lk-offers .lk-offers-card:hover .desc p,
.section-6 .lk-offers .lk-offers-card.active .desc p {
  color: #fff;
}

.section-6 .lk-offers .lk-offers-card .offers {
  margin-bottom: 2rem;
  padding: 0 20px;
}

.section-6 .lk-offers .lk-offers-card .offers ul {
  margin-bottom: 0;
  padding: 0;
}

.section-6 .lk-offers .lk-offers-card .offers ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-6 .lk-offers .lk-offers-card .offers ul li span {
  color: #5e5454;
}

.section-6 .lk-offers .lk-offers-card:hover .offers ul li span,
.section-6 .lk-offers .lk-offers-card.active .offers ul li span {
  color: #fff;
}

.section-6 .lk-offers .lk-offers-card .offers ul li:not(:last-child) {
  margin-bottom: 10px;
}

.section-6 .lk-offers .lk-offers-card .offers ul li div {
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #ffbfbf;
  border-radius: 50%;
  margin-right: 10px;
}

.section-6 .lk-offers .lk-offers-card .offers ul li div svg {
  color: #fff;
}

.section-6 .lk-offers .lk-offers-card .action {
  padding: 0 20px 20px 20px;
}

.section-6 .lk-offers .lk-offers-card:hover .action a,
.section-6 .lk-offers .lk-offers-card.active .action a,
.section-6 .lk-offers .lk-offers-card:hover .action button,
.section-6 .lk-offers .lk-offers-card.active .action button {
  background-color: #fff !important;
  color: #ea5153 !important;
}

.section-6 .lk-offers-nav {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 20px;
}

.section-6 .lk-offers-nav button {
  font-weight: 400;
  font-weight: 400;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.section-6 .lk-offers-nav button:not(:disabled) {
  color: #ee5253;
}

.section-6 .lk-offers-nav button:focus {
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

body[data-theme='dark'] .setting-nav li {
  color: #fff !important;
}

body[data-theme='light'] .setting-nav li {
  color: #222 !important;
}

.setting-nav li::after {
  display: none !important;
}

.wrapper {
  max-width: 100%;
  overflow: hidden;
}

.marquee {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  animation: marquee 15s linear infinite;
  width: 100%;
}

.marquee p {
  display: inline-block;
}

@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(50%, 0, 0);
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
}

.pageLoading {
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.pageLoading img:first-child {
  animation-name: cutting;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier();
  position: relative;
  top: 5px;
}

.pageLoading img:nth-child(2) {
  transform: scaleX(-1) skewY(5deg);
}

@keyframes cutting {
  0% {
    transform: rotateZ(-170deg) rotateY(180deg);
  }

  100% {
    transform: rotateZ(-190deg) rotateY(180deg);
  }
}

.profile-navs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.lum-banner {
  background-color: #3e91f7 !important;
  padding: 1rem !important;
  border-radius: 10px !important;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;
}

.lum-banner .closeBtn {
  position: absolute;
  right: 15px;
  top: 15px;
  color: #fff;
  z-index: 2;
}

.lum-banner p {
  color: #fff !important;
  font-weight: 700 !important;
}

.lum-banner .sw-pag {
  list-style: none;
  margin-top: 20px;
  margin-bottom: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  gap: 10px;
}

.lum-banner .sw-pag li {
  width: 23px;
  height: 13px;
  background: #67a8f9;
  border-radius: 90px;
}

.lum-banner .sw-pag li.active {
  background-color: #fff;
}

.lum-banner .notif-item {
  padding: 0 10px;
}

.lum-banner .notif-item .notif-badge {
  background: #5ba1f8;
  border-radius: 4px;
  padding: 3px 5px;
  color: #fff;
  font-family: 'Poppins', inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 15.6px;
  line-height: 20px;
  display: inline-block;
  margin-bottom: 10px;
}

.feature-icon-box {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.feature-icon-box img {
  width: 150px;
  height: 150px;
}

.ant-floatinbtn {
  position: fixed !important;
  right: 15px;
  bottom: 30px;
  z-index: 2147483640;
}

.lum-banner {
  background-image: url(https://cdn.1cdn.app/application/LUMBERJACK/blue_background.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.example {
  display: -ms-grid;
  display: grid;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: -webkit-gradient(linear, left top, left bottom, from(white), to(black));
  background: -o-linear-gradient(top, white, black);
  background: linear-gradient(to bottom, white, black);
}

.support-page #img-1 {
  width: 180px;
}

.ant-layout-sider-children {
  height: 125% !important;
}

.whiteTimer g {
  fill: rgb(179, 179, 179);
}

.steps-content .ant-avatar img {
  object-fit: cover !important;
}

.qr-image {
  max-width: 200px;
  max-height: 200px;
}

.swagger-modal .swagger-modal-content .avatar-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 16px auto;
}

.swagger-modal .swagger-modal-content .title-box {
  text-align: center;
  margin-bottom: 10px;
}

.swagger-modal .ant-modal-footer {
  border-top: none;
}

.swagger-modal .copy-area {
  padding: 10px 24px;
  background: #cee3fd;
  border-radius: 10px;
  color: #1c78e9;
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
  margin-bottom: 10px;
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 75%;
  margin: 0 auto 15px auto;
}

.swagger-modal .copy-area button {
  color: #1c78e9;
}

.swagger-modal .ant-typography.pr {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.end-plan {
  background-color: #fff;
  border-radius: 15px;
  margin-top: 20px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 30px 0;
}

.end-plan h4 {
  font-family: 'Poppins', inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 27.86px;
  color: #6d6d6d;
  margin-bottom: 0;
  text-align: center;
  margin-top: 15px;
}

.end-plan p {
  font-family: 'Poppins', inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 17.86px;
  color: #a4a4a4;
  text-align: center;
}

.end-plan .actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.notifications-box {
  width: 100%;
}

.social-auth-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.social-auth-container .s-icon button {
  background: #ffffff;
  border: 1px solid #c9c9c9;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 6px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0a042d;
  cursor: pointer;
  min-width: 250px;
}

.social-auth-container .s-icon button img {
  margin-right: 8px;
}

.ant-row-rtl .social-auth-container .s-icon button img {
  margin-right: unset;
  margin-left: 8px;
}

.need-upgrade-feature {
  position: relative;
}

.need-upgrade-feature .overlay {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.need-upgrade-feature .overlay h1 {
  font-size: 35px;
  margin-bottom: 1rem;
}

.need-upgrade-feature .overlay h1,
.need-upgrade-feature .overlay p {
  color: #fff;
}

.ant-menu-title-content .ant-badge {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
}

.cs-badge {
  background-color: rgba(221, 93, 89, 0.12);
  padding: 3px 5px;
  border-radius: 4px;
  position: relative !important;
  margin-top: 20px;
  margin-right: 10px;
}

/* Responsive */
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    white-space: normal !important;
  }

  .profile-navs {
    flex-flow: column;
  }

  .profile-navs .ant-avatar {
    margin-bottom: 20px;
  }

  .ant-page-header-heading-title {
    overflow: auto !important;
    white-space: unset !important;
  }

  .ant-page-header-heading-left {
    flex-flow: column !important;
    align-items: flex-start !important;
  }

  .marquee p {
    white-space: initial;
  }

  .ant-floatinbtn {
    width: initial !important;
  }

  .ant-layout-sider-collapsed {
    display: none;
  }

  .ant-layout {
    margin: 0 !important;
  }

  .ant-layout-has-sider.menuOpen {
    transition: all 0.3s;
  }

  .ant-layout-has-sider.menuOpen::before {
    content: '';
    display: block;
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .swagger-modal .copy-area {
    font-size: 14px;
  }

  .logout-modal {
    text-align: center !important;
  }

  .logout-modal h5 {
    margin-top: 20px;
  }

  .swagger-modal .copy-area {
    width: 100%;
  }

  .swagger-modal .copy-area .ant-divider-vertical {
    display: none;
  }

  .end-plan img {
    width: 80%;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .trigger {
    display: none !important;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .trigger {
    display: none !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* .section-6 .lk-offers-nav {
    display: none;
  } */

  .trigger {
    display: none !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* .section-6 .lk-offers-nav {
    display: none;
  } */

  .trigger {
    display: none !important;
  }
}

.input-clear {
  border: none !important;
}

.input-clear:focus {
  border: none !important;
  border-color: none !important;
  box-shadow: none !important;
  outline: none !important;
  border-right-width: none !important;
}

.input-clear {
  border: none !important;
}

.input-clear:focus {
  border: none !important;
  border-color: none !important;
  box-shadow: none !important;
  outline: none !important;
  border-right-width: none !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: transparent !important;
}

.ant-avatar-string {
  left: unset !important;
}

.ant-modal-close-x {
  height: unset !important;
  width: unset !important;
}
